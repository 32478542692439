<template>
  <div class="container">
    <el-container>
      <el-header height="48px">
        <el-form :inline="true" :model="filterOption" size="mini">
          <el-row>
            <el-form-item>
              <el-input
                v-model="filterOption.name"
                :placeholder="$t('organization.name')"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-input
                v-model="filterOption.code"
                :placeholder="$t('organization.code')"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="filterOption.city"
                :placeholder="$t('organization.city')"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-select
                filterable
                v-model="filterOption.supOrgId"
                :placeholder="$t('organization.supOrganization')"
                clearable
              >
               <el-option
              :label="item.companyNameCn"
              :value="'' + item.id"
              v-for="(item, index) in organizationList"
              :key="index"
            >
              <span style="float: left">{{ item.companyNameCn }}</span>
              <span
                style="
                  float: right;
                  color: 'var(--el-text-color-secondary)';
                  font-size: 12px;
                "
                >{{ item.companyCode }}</span
              ></el-option
            >
              </el-select>
            </el-form-item>
          </el-row>
        </el-form>
        <div class="rightBtn">
          <el-button type="primary" size="mini">{{
            $t("system.search")
          }}</el-button>
          <el-button type="warning" size="mini">{{
            $t("system.reset")
          }}</el-button>
        </div>
      </el-header>
      <el-main>
        <div class="tableHeader">
          <el-button size="mini">{{ $t("system.import") }}</el-button>
          <el-button size="mini" @click="showCreateDialog">{{
            $t("system.create")
          }}</el-button>
          <el-button size="mini">{{ $t("system.delete") }}</el-button>
        </div>
        <el-table
          :data="tableData"
          ref="accountTable"
          border
          :height="tHeight"
          v-loading="loading"
          @selection-change="onTableSelectionChange"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column type="selection" width="40" fixed></el-table-column>
          <el-table-column
            type="index"
            :label="$t('system.index')"
            width="60"
            align="center"
            fixed
          ></el-table-column>
          <el-table-column
            prop="name"
            :label="$t('organization.name')"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="code"
            :label="$t('organization.code')"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="prefix"
            :label="$t('organization.prefix')"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="city"
            :label="$t('organization.city')"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="supOrganizationName"
            :label="$t('organization.supOrganization')"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="remark"
            :label="$t('organization.remark')"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('system.operation')"
            fixed="right"
            align="center"
            width="150"
          >
            <template v-slot="scope">
              <el-link
                type="primary"
                icon="el-icon-edit"
                @click="edit(scope.row)"
                >{{ $t("system.edit") }}</el-link
              >
              <el-link
                type="warning"
                icon="el-icon-delete"
                @click="del(scope.row)"
                style="margin-left: 20px"
                >{{ $t("system.delete") }}</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="float: right; margin-top: 10px">
          <el-pagination
            @size-change="onTableSizeChange"
            @current-change="onTablePageChange"
            :current-page="filterOption.pageNo"
            :page-sizes="[10, 50, 100, 200]"
            layout="total,sizes,prev, pager, next"
            :total="filterOption.total"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>
    <el-dialog title="提示" v-model="isShowCreateDialog" width="30%">
      <span>这是一段信息</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isShowCreateDialog = false">取 消</el-button>
          <el-button type="primary" @click="isShowCreateDialog = false"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>


<script>
import mixin from "@/utils/mixin.js";
export default {
  name: "organization",
  mixins: [mixin],
  data() {
    return {
      loading:false,
      isShowCreateDialog: false,
      filterOption: {
        supOrgId: null,
        name: null,
        code: null,
        city: null,
        pageNo: 1,
        pageSize: 10,
        total: 5,
      },

      // organizationList: [
      //   { id: 1, name: "壹站供应链管理有限公司" },
      //   { id: 2, name: "上海道骊物流有限公司" },
      // ],

      tableData: [
        {
          name: "ranger",
          supOrganizationName: "上海道骊物流有限公司",
          sex: "男",
          code: "12382341234",
          prefix: "OITA",
          city: "上海市",
          remark: "备注",
        },
        {
          name: "lj",
          supOrganizationName: "上海道骊物流有限公司",
          sex: "男",
          code: "12382341234",
          prefix: "OITB",
          city: "上海市",
          remark: "备注",
        },
        {
          name: "ph",
          supOrganizationName: "上海道骊物流有限公司",
          sex: "男",
          code: "12382341234",
          prefix: "OITC",
          city: "上海市",
          remark: "备注",
        },
        {
          name: "sh",
          supOrganizationName: "上海道骊物流有限公司",
          sex: "男",
          code: "12382341234",
          prefix: "OITD",
          city: "上海市",
          remark: "备注",
        },
        {
          name: "zsm",
          supOrganizationName: "上海道骊物流有限公司",
          sex: "男",
          code: "12382341234",
          prefix: "OITE",
          city: "上海市",
          remark: "备注",
        },
      ],
      tableSelection: [],
    };
  },
  computed: {
        organizationList(){
      return this.$store.state.constants.saleCompanys;
    },
    tHeight() {
      return this.tableHeight - 150;
    },
  },
  mounted() {},
  methods: {
    edit(row) {
      console.log(row);
    },
    del(row) {
      console.log(row);
    },
    onTableSizeChange(size) {
      console.log(size);
    },
    onTablePageChange(page) {
      console.log(page);
    },
    showCreateDialog() {
      this.isShowCreateDialog = true;
    },
    closeCreateDialog() {
      this.isShowCreateDialog = false;
    },
    onTableSelectionChange(val) {
      this.tableSelection = val;
    },
  },
};
</script>


<style lang="less" scoped>
.container {
  height: calc(100% - 20px);
  background-color: white;
  overflow-y: scroll;
  padding: 10px;

  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
    :deep(el-form-item) {
      margin-right: 10px;
    }
  }

  :deep(.el-main) {
    padding: 0 20px;
    .tableHeader {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 48px;
    }
  }
}
</style>
